.game
  position: relative
  display: grid
  box-shadow: 0 6px 40px rgba(23, 27, 36, 0.25)
  border-radius: 40px
  color: #FFFFFF
  box-sizing: border-box
  grid-template: repeat(4, max-content) / auto
  grid-template-areas: 'image' 'title' 'descr' 'button'
  gap: 16px 31px
  max-width: 920px
  width: calc(100vw - 40px)
  font-size: 16px
  line-height: 1.3
  padding: 20px

  &::before,
  &::after
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    content: ''
    border-radius: 40px

  &::before
    background-image: url('./images/game-bg-big.png')
    background-repeat: no-repeat
    background-size: cover
    mix-blend-mode: multiply
    z-index: -1

  &::after
    background: linear-gradient(71.55deg, rgba(6, 26, 98, 0.75) 7.71%, rgba(70, 4, 122, 0.75) 89.93%) #FFFFFF no-repeat
    background-size: cover
    z-index: -2

  @media screen and (max-width: 849px)
    border-radius: 24px

    &::before,
    &::after
      border-radius: 24px

  @media screen and (min-width: 850px)
    grid-template: max-content 1fr max-content / max-content 1fr
    grid-template-areas: 'image title' 'image descr' 'image button'
    font-size: 32px
    padding: 95px 48px 38px 55px

.title
  grid-area: title
  font-weight: 700

.descr
  grid-area: descr

.image
  grid-area: image

  @media screen and (max-width: 849px)
    text-align: center
    margin-top: -33px

.button
  grid-area: button
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  background-color: #6AB81F
  border: 8px solid #FFFFFF
  border-radius: 24px
  font-weight: 700
  font-size: 24px
  line-height: 1
  color: #FFFFFF
  text-decoration: none
  padding: 16px 8px

  @media screen and (min-width: 850px)
    margin-top: 67px
