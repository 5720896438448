
.banner_wrapper
  position: relative
  display: grid
  grid-template-columns: 1fr 1fr
  grid-auto-rows: 2em
.promo_price
  border: 5px solid #E7C351
  height: fit-content
  border-radius: 20px
  padding: 5px
.promo_list
  margin-left: 50px
  font-weight: normal
  font-size: 25px
  margin-top: 68px
.spring
  position: relative
  display: grid
  box-shadow: 0 6px 40px rgba(23, 27, 36, 0.25)
  border-radius: 40px
  color: black
  height: 450px
  background: #FFFFFF

  @media screen and (max-width: 849px)
    border-radius: 24px

    &::before,
    &::after
      border-radius: 24px

.title
  grid-area: title
  font-weight: 700
  font-size: 48px
.accent
  font-weight: 900
  color: #FFE8AD

.red__text
   color: #EC6761
   font-weight: 500
   -webkit-text-stroke: 0.5px white
   font-size: 40px
.sale
  grid-area: sale
  font-weight: 700

.descr
  grid-area: descr
  font-weight: 700
  margin-bottom: 20px
.note
  grid-area: note

.image
  position: absolute
  right:  55px
  bottom: 45px

.button
  box-shadow: 10px 5px 5px black
  margin-top: 118px
  grid-area: button
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  background-color: #0166FE

  border-radius: 24px
  font-weight: 700
  font-size: 24px
  line-height: 1
  color: #FFFFFF
  text-decoration: none
  padding: 16px 8px

.big
  grid-template: repeat(5, min-content) / max-content 1fr
  grid-template-areas: 'title title' 'sale sale' 'image descr' 'image note' 'button button'
  //max-width: 296px
  width: calc(100vw - 40px)
  font-size: 20px
  line-height: 0.7
  font-weight: bold
  padding: 20px



  .accent
    font-size: 24px

  .sale
    font-size: 105px
    line-height: 1
    align-self: center

  .note
    font-size: 16px

  @media screen and (max-width: 849px)
    .descr
      align-self: center

    .note
      align-self: end
      margin-bottom: 15px

    .image
      margin-right: -20px

  @media screen and (min-width: 850px)
    grid-template-areas: 'image title' 'image sale' 'image descr' 'image note' 'image button'
    gap: 15px 0
    max-width: 820px
    font-size: 36px
    padding: 48px 48px 48px 16px

    .accent
      font-size: 40px

    .sale
      font-size: 170px
      line-height: 1
      align-self: center

    .note
      font-size: 24px

    .image
      margin-top: -104px
      margin-bottom: -46px

.small
  display: flex
  gap: 9px 0
  border-radius: 24px
  max-width: 340px
  width: calc(100vw - 40px)
  font-size: 18px
  line-height: 1
  padding: 12px

  &::before
    background-image: url('./images/bg.png')
    background-size: 2400px
  .accent
    font-size: 22px

  .sale
    font-size: 32px
    padding-left: 95px
    margin-top: -30px

  .note
    font-size: 11px

  .image
    position: absolute
    right:  45px
    bottom: 5px


  @media screen and (max-width: 849px)
    .button
      border-radius: 12px
      border-width: 4px
      font-size: 18px
      padding: 6px

  @media screen and (min-width: 850px)
    max-width: 712px
    font-size: 32px
    padding: 24px

    .accent
      font-size: 36px

    .sale
      font-size: 86px
      padding-left: 180px
      margin-top: -50px

    .note
      font-size: 22px


.routes
  grid-template: min-content 1fr repeat(3, min-content) / 1fr
  grid-template-areas: 'title' '.' 'sale' 'descr' 'note' 'button'
  gap: 10px 0
  border-radius: 24px
  height: 100%
  font-size: 20px
  line-height: 1
  padding: 20px

  &::before
    background-image: url('./images/spring-bg-routes.png')

  .accent
    font-size: 24px

  .sale
    font-size: 99px

  .note
    font-size: 14px

  @media screen and (max-width: 849px)
    .image
      display: none

  @media screen and (min-width: 850px)
    grid-template: min-content 1fr repeat(3, min-content) / max-content 1fr
    grid-template-areas: 'title title' '. .' 'image sale' 'image descr' 'image note' 'button button'
    font-size: 24px

    .accent
      font-size: 32px

    .sale
      font-size: 95px

    .descr
      margin-top: 40px

    .note
      font-size: 21px

    .image
      margin-right: -40px
      margin-left: -20px




