.autumn
  position: relative
  display: grid
  box-shadow: 0 6px 40px rgba(23, 27, 36, 0.25)
  border-radius: 40px
  color: #FFFFFF
  box-sizing: border-box

  &::before,
  &::after
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    content: ''
    border-radius: 40px

  &::before
    background-repeat: no-repeat
    background-size: cover
    mix-blend-mode: multiply
    z-index: -1

  &::after
    background-image: linear-gradient(254deg, #CD6A4B 3.79%, #D7B45B 97.74%)
    background-repeat: no-repeat
    background-size: cover
    z-index: -2

  @media screen and (max-width: 849px)
    border-radius: 24px

    &::before,
    &::after
      border-radius: 24px

.title
  grid-area: title
  font-weight: 700

.accent
  font-weight: 900
  color: #FFE8AD

.sale
  grid-area: sale
  font-weight: 700

.descr
  grid-area: descr
  font-weight: 700

.note
  grid-area: note

.image
  grid-area: image

.button
  grid-area: button
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  background-color: #D06D10
  border: 8px solid #FFFFFF
  border-radius: 24px
  font-weight: 700
  font-size: 24px
  line-height: 1
  color: #FFFFFF
  text-decoration: none
  padding: 16px 8px

.big
  grid-template: repeat(5, min-content) / max-content 1fr
  grid-template-areas: 'title title' 'sale sale' 'image descr' 'image note' 'button button'
  max-width: 296px
  width: calc(100vw - 40px)
  font-size: 20px
  line-height: 1.2
  padding: 20px

  &::before
    background-image: url('./images/autumn-bg-big.png')

  .accent
    font-size: 24px

  .sale
    font-size: 105px
    line-height: 1
    align-self: center

  .note
    font-size: 16px

  @media screen and (max-width: 849px)
    .descr
      align-self: center

    .note
      align-self: end
      margin-bottom: 15px

    .image
      margin-right: -20px

  @media screen and (min-width: 850px)
    grid-template-areas: 'image title' 'image sale' 'image descr' 'image note' 'image button'
    gap: 15px 0
    max-width: 920px
    font-size: 36px
    padding: 48px 48px 48px 16px

    .accent
      font-size: 40px

    .sale
      font-size: 170px
      line-height: 1
      align-self: center

    .note
      font-size: 24px

    .image
      margin-top: -104px
      margin-bottom: -46px

.small
  grid-template: repeat(5, min-content) / max-content 1fr
  grid-template-areas: 'image title' 'image sale' 'image descr' 'image note' 'image button'
  gap: 9px 0
  border-radius: 24px
  max-width: 340px
  width: calc(100vw - 40px)
  font-size: 18px
  line-height: 1
  padding: 12px

  &::before
    background-image: url('./images/autumn-bg-small.png')

  .accent
    font-size: 22px

  .sale
    font-size: 32px
    padding-left: 95px
    margin-top: -30px

  .note
    font-size: 11px

  .image
    margin-top: -8px
    margin-bottom: -8px

  @media screen and (max-width: 849px)
    .button
      border-radius: 12px
      border-width: 4px
      font-size: 18px
      padding: 6px

  @media screen and (min-width: 850px)
    max-width: 712px
    font-size: 32px
    padding: 24px

    .accent
      font-size: 36px

    .sale
      font-size: 86px
      padding-left: 180px
      margin-top: -50px

    .note
      font-size: 22px

    .image
      margin-top: -51px
      margin-bottom: -8px

.routes
  grid-template: min-content 1fr repeat(3, min-content) / 1fr
  grid-template-areas: 'title' '.' 'sale' 'descr' 'note' 'button'
  gap: 10px 0
  border-radius: 24px
  height: 100%
  font-size: 20px
  line-height: 1
  padding: 20px

  &::before
    background-image: url('./images/autumn-bg-routes.png')

  .accent
    font-size: 24px

  .sale
    font-size: 99px

  .note
    font-size: 14px

  @media screen and (max-width: 849px)
    .image
      display: none

  @media screen and (min-width: 850px)
    grid-template: min-content 1fr repeat(3, min-content) / max-content 1fr
    grid-template-areas: 'title title' '. .' 'image sale' 'image descr' 'image note' 'button button'
    font-size: 24px

    .accent
      font-size: 32px

    .sale
      font-size: 95px

    .descr
      margin-top: 40px

    .note
      font-size: 21px

    .image
      margin-right: -40px
      margin-left: -20px
