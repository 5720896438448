.friday
  position: relative
  display: grid
  box-shadow: 0 6px 40px rgba(23, 27, 36, 0.25)
  border-radius: 40px
  color: #FFFFFF
  background-color: #170101
  box-sizing: border-box

  @media screen and (max-width: 849px)
    border-radius: 24px

.title
  grid-area: title
  font-weight: 700
  text-transform: uppercase

.sale
  grid-area: sale
  font-weight: 700
  color: #FFF813

.descr
  grid-area: descr

.accent
  color: #FFF813

.image
  grid-area: image

.button
  grid-area: button
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  background-color: #FFF813
  border: 8px solid #FFFFFF
  border-radius: 24px
  font-weight: 700
  font-size: 24px
  line-height: 1
  color: #170101
  text-decoration: none
  padding: 16px 8px

.big
  grid-template: repeat(4, min-content) / max-content 1fr
  grid-template-areas: 'title title' 'sale sale' 'image descr' 'button button'
  max-width: 296px
  width: calc(100vw - 40px)
  font-size: 20px
  line-height: 1.2
  padding: 20px

  .title
    font-size: 43px

  .sale
    font-size: 24px
    line-height: 1
    align-self: center

  @media screen and (max-width: 849px)
    .sale
      margin-left: 100px

    .descr
      align-self: center
      margin-left: -25px
      margin-top: -50px

    .image
      margin-left: -20px
      margin-top: -20px

    .button
      margin-top: -50px

  @media screen and (min-width: 850px)
    grid-template-areas: 'image title' 'image sale' 'image descr' 'image button'
    gap: 15px 0
    max-width: 920px
    font-size: 36px
    padding: 48px 48px 48px 16px

    .title
      font-size: 84px

    .sale
      font-size: 50px
      line-height: 1
      align-self: center

    .descr
      font-size: 38px

    .image
      margin-top: -104px
      margin-bottom: -46px

.small
  grid-template: repeat(4, min-content) / max-content 1fr
  grid-template-areas: 'image title' 'image sale' 'image descr' 'image button'
  gap: 9px 0
  border-radius: 24px
  max-width: 340px
  width: calc(100vw - 40px)
  font-size: 18px
  line-height: 1
  padding: 12px

  .title
    font-size: 20px

  .sale
    font-size: 17px

  .descr
    font-size: 16px

  .image
    margin-bottom: -20px

  @media screen and (max-width: 849px)
    .button
      border-radius: 12px
      border-width: 4px
      font-size: 18px
      padding: 6px

  @media screen and (min-width: 850px)
    max-width: 712px
    font-size: 32px
    padding: 24px

    .title
      font-size: 38px

    .sale
      font-size: 36px

    .descr
      font-size: 32px

    .image
      margin-top: -51px
      margin-bottom: -30px

.routes
  grid-template: min-content 1fr repeat(3, min-content) / max-content 1fr
  grid-template-areas: 'title title' '. .' 'image sale' 'image descr' 'button button'
  gap: 10px 0
  border-radius: 24px
  height: 100%
  font-size: 20px
  line-height: 1
  padding: 20px

  .title
    font-size: 42px

  .sale
    font-size: 29px

  @media screen and (max-width: 849px)
    .image
      margin-left: -20px

  @media screen and (min-width: 850px)
    font-size: 24px

    .title
      font-size: 51px

    .sale
      font-size: 29px

    .descr
      font-size: 23px
      margin-top: -60px

    .image
      margin-right: -30px
      margin-left: -20px
