.lottery
  position: relative
  display: grid
  box-shadow: 0 6px 40px rgba(23, 27, 36, 0.25)
  border-radius: 40px
  color: #FFFFFF
  box-sizing: border-box

  &::before,
  &::after
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    content: ''
    border-radius: 40px

  &::before
    background-repeat: no-repeat
    background-size: cover
    mix-blend-mode: multiply
    z-index: -1

  &::after
    background-image: linear-gradient(42deg, rgba(112, 129, 197, 0.95) 0%, rgba(104, 132, 240, 0.95) 100%)
    background-color: #FFFFFF
    background-repeat: no-repeat
    background-size: cover
    z-index: -2

  @media screen and (max-width: 849px)
    border-radius: 24px

    &::before,
    &::after
      border-radius: 24px

.title
  grid-area: title
  font-weight: 900

.sale
  grid-area: sale
  font-weight: 500
  color: #F5CB2F

.descr
  grid-area: descr
  font-weight: 400

.prizes
  grid-area: prizes
  display: none

.image
  grid-area: image
  max-width: 100%
  height: auto

  & > *
    max-width: 100%
    height: auto

.button
  grid-area: button
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  background-color: #D477B2
  border: 8px solid #FFFFFF
  border-radius: 24px
  font-weight: 400
  font-size: 24px
  line-height: 1
  color: #FFFFFF
  text-decoration: none
  padding: 16px 8px

.terms
  position: absolute
  color: #FFFFFF
  text-decoration: none

.big
  grid-template: repeat(5, min-content) / max-content 1fr
  grid-template-areas: 'title title' 'prizes prizes' 'image image' 'sale sale' 'descr descr' 'button button'
  max-width: 296px
  width: calc(100vw - 40px)
  font-size: 32px
  line-height: 1.2
  padding: 20px 20px 30px

  &::before
    background-image: url('./images/lottery-bg-big.png')

  .title
    font-size: 41px
    text-align: center

  .prizes
    display: block
    max-width: 100%
    height: auto

    & > *
      max-width: 100%
      height: auto

  @media screen and (max-width: 849px)
    font-size: 16px
    gap: 10px 0
    padding-top: 50px

    .image
      display: none

    .descr
      text-align: center

    .sale
      text-align: center

    .button
      margin-top: 10px

    .terms
      font-size: 12px
      top: 95px
      right: 55px

  @media screen and (min-width: 850px)
    grid-template-areas: 'title title' 'prizes prizes' 'image sale' 'image descr' 'image button'
    gap: 15px 60px
    max-width: 920px
    font-size: 36px
    padding: 48px

    .title
      font-size: 99px

    .image
      margin-bottom: -46px

    .prizes
      margin-bottom: -80px

    .terms
      font-size: 16px
      bottom: 15px
      right: 48px

.small
  grid-template: repeat(5, min-content) / 1fr
  grid-template-areas: 'title' 'image' 'sale' 'descr' 'button'
  gap: 9px 10px
  max-width: 340px
  width: calc(100vw - 40px)
  font-size: 16px
  line-height: 1
  padding: 50px 12px 12px

  &::before
    background-image: url('./images/lottery-bg-small.png')

  .title
    font-size: 41px

  .descr
    font-size: 16px

  @media screen and (max-width: 849px)
    text-align: center
    border-radius: 24px

    .title
      margin-bottom: 10px

    .button
      border-radius: 12px
      border-width: 4px
      font-size: 18px
      padding: 6px

    .terms
      font-size: 9px
      top: 90px
      right: 80px

  @media screen and (min-width: 850px)
    grid-template: repeat(4, min-content) / max-content 1fr
    grid-template-areas: 'image title' 'image sale' 'image descr' 'image button'
    max-width: 712px
    font-size: 24px
    padding: 24px 24px 40px

    .title
      font-size: 62px

    .descr
      font-size: 32px

    .image
      margin: -40px 0 0 -20px

    .terms
      font-size: 16px
      bottom: 12px
      right: 24px

.routes
  display: flex
  flex-direction: column
  align-items: center
  row-gap: 10px
  text-align: center
  height: 100%
  font-size: 16px
  line-height: 1.1
  padding: 20px

  &::before
    background-image: url('./images/lottery-bg-routes.png')

  .title
    font-size: 41px

  .button
    align-self: stretch
    margin-top: auto

  .terms
    font-size: 12px
    top: 80px
    right: 25px

  @media screen and (max-width: 849px)
    border-radius: 24px

    .terms
      top: 70px

  @media screen and (min-width: 850px)
    font-size: 16px

    .title
      font-size: 51px

    .descr
      font-size: 22px
