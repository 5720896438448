.physics
  position: relative
  display: grid
  box-shadow: 0 6px 40px rgba(23, 27, 36, 0.25)
  border-radius: 40px
  color: #FFFFFF
  box-sizing: border-box

  &::before,
  &::after
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    content: ''
    border-radius: 40px

  &::before
    background-repeat: no-repeat
    background-size: cover
    mix-blend-mode: multiply
    z-index: -1

  &::after
    background-image: linear-gradient(119.15deg, #451CB8 9.82%, #17A482 88.85%)
    background-repeat: no-repeat
    background-size: cover
    z-index: -2

  @media screen and (max-width: 849px)
    border-radius: 24px

    &::before,
    &::after
      border-radius: 24px

.title
  grid-area: title
  font-weight: 700

.sale
  grid-area: sale
  font-weight: 700

.image
  grid-area: image

.button
  grid-area: button
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  background: #6AB81F
  border: 8px solid #FFFFFF
  border-radius: 24px
  font-weight: 700
  font-size: 24px
  line-height: 1
  color: #FFFFFF
  text-decoration: none
  padding: 16px 8px

.big
  grid-template: repeat(4, min-content) / 1fr
  grid-template-areas: 'image' 'title' 'sale' 'button'
  gap: 10px 0
  max-width: 296px
  width: calc(100vw - 40px)
  font-size: 18px
  line-height: 1
  padding: 20px

  &::before
    background-image: url('./images/physics-bg-big.png')

  .sale
    font-size: 105px
    line-height: 1
    align-self: center

  .image
    margin-top: -60px
    margin-bottom: -30px

  @media screen and (max-width: 849px)
    .image
      justify-self: center

  @media screen and (min-width: 850px)
    grid-template: repeat(3, min-content) / max-content 1fr
    grid-template-areas: 'image title' 'image sale' 'image button'
    gap: 15px 0
    max-width: 920px
    font-size: 34px
    line-height: 1.3
    padding: 110px 48px 48px 0

    .sale
      font-size: 170px
      line-height: 1
      align-self: center

    .image
      margin-left: -115px
      margin-top: -120px
      margin-bottom: -100px
