.black_friday_promo{
  position:absolute;
  width:920px;
  height:568px;
  z-index:999999;
  background:#89D5E8;
  left:50%;
  border-radius:40px;
  top:50%;
  transform:translate(-50%, -50%);
  @media screen and (max-width: 1024px){
    width:376px;
    height:460px;
  }

}

.sale{
  position:absolute;
  font-size: 50px;
  color:#E4002F;
  left: 72px;
  top: 200px;
  @media screen and (max-width: 1024px){
    left:20px;
    font-size: 36px;
    top: 190px;

  }
}


.black_friday_title{

  position:absolute;
  left:72px;
  top: 54px;
  font-size:60px;
  color:white;


  @media screen and (max-width: 1024px){
    width: 317px;
    font-weight: 900;
    font-size:51px;
    left:20px;
    top: 40px;

  }
}


.ninja_image{
  position:absolute;
  bottom: -50px;
  left: 450px;
  @media screen and (max-width: 1024px){
    width:141px;
    height: 236px;
    bottom:55px;
    left: 70%;
  }
}

.desc{
  position:absolute;
  color: white;
  left:72px;
  width: 469px;
  top:267px;
  font-size: 50px;
  @media screen and (max-width: 1024px) {
    font-size: 28px;
    left: 20px;
    width: 169px;
    top: 240px;
  }
}

.button{
  cursor: pointer;
  left: 232px;
  width: 305px;
  height:60px;
  text-align: center;
  line-height: 60px;
  transform: translate(-50%,0);
  position:absolute;
  bottom:40px;
  background-color: #E4002F;
  text-decoration: none;
  color: white;
  font-size: 30px;
  border-radius: 18px;
  @media screen and (max-width: 1024px) {
    transform: translate(0,0);
    left: 20px;

  }

}

.close_icon{
  cursor: pointer;
  position: absolute;
  top: 22px;
  right: 22px;
}
