@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

.main * {
  padding: 0;
  margin: 0;
}
.none {
  display: none;
}
.main {
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 99999;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  /* background:url("https://game.chevostik.ru/uploads/lesson/image/58/large_preview.jpg"); */
  background-size: cover;
  background-position: center;
  background-color: white;
}
.modalMain {
  position: fixed;
  z-index: 99999;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  /* background:url("https://game.chevostik.ru/uploads/lesson/image/58/large_preview.jpg"); */
  background-size: cover;
  background-position: center;
  background-color: white;
  display: none;
}
.header {
  width: 100vw;
  height: 88px;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btnClose {
  padding: 0;
  width: 64px;
  height: 64px;
  background-image: url("./files/Close.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  box-sizing: unset;
  flex: 0 0 64px;
}

.lessonTitle {
  font-family: CraftworkGrotesk;
  font-size: 20px;
  font-weight: 700;
  line-height: 21.12px;
  text-align: center;
  color: #958807;
}

.btnQuestion {
  width: 64px;
  height: 64px;
  background-image: url("./files/Question.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  flex: 0 0 64px;
}

.promo {
  padding: 10px;
  width: 90vw;
  height: max-content;
  min-height: 101px;
  margin: auto;
  background-color: #d0f8f5;
  border-radius: 15px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}

.BlueText {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 3;
  font-family: CraftworkGrotesk;
  font-size: 16px;
  font-weight: 900;
  line-height: 16.9px;
  text-align: left;
  color: #0ea4fb;
}

.OrangeText {
  margin-left: 10px;
  font-family: CraftworkGrotesk;
  font-size: 12px;
  font-weight: 900;
  line-height: 12.67px;
  text-align: left;
  color: #fe8121;
}

.OrangeText span {
  display: block;
  font-size: 24px;
  line-height: 24px;
  text-align: left;
}

.btnBuy {
  width: 178px;
  height: 50px;
  border: 6px solid white;
  border-radius: 10px;
  background-color: #0ea4fb;
  font-family: CraftworkGrotesk;
  font-size: 14px;
  font-weight: 900;
  line-height: 14.78px;
  text-align: center;
  color: white;
  box-shadow: 0px 0px 6px 0px #0000004d inset;
}

.wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  /* max-height: calc(100vh - (58px + 100px)); */
}

.leftSidebar {
  width: 85vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0px;
  height: 100%;
}

@media screen and (max-height: 428px) {
  .wrapper {
    height: calc(100vh - 88px);
  }
  /* .leftSidebar{
        flex-direction: row;
    } */
  .contentFrame {
    position: relative;
    left: 15%;
  }
  .smallPlayer {
    position: relative;
    left: 5%;
    top: -20%;
  }
}

.contentFrame {
  width: 90%;
  max-width: 60vh;
  max-height: 70vh;
  border-radius: 16px;
  opacity: 1;
  box-shadow: 0px 2px 10px 0px #00000040;
  margin: 15px auto;
}

.contentFrame:after {
  content: "";
  display: block;
}

.content {
  width: 100%;
  height: 100%;
}

.largePlayer {
  padding: 10px;
  width: auto;
  /* min-height: 90px; */
  border: 5px solid #ebedf0;
  border-radius: 17px;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  background-color: white;
  max-width: 90%;
  height: auto;
  justify-content: space-between;
  align-items: center;
}

.facts {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.factName {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
  color: #000000;
}

.factNumber {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: #686f7a;
}

.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.factsMenu {
  background-image: url("./files/List.png");
  width: 50px;
  height: 50px;
  opacity: 0.9px;
}

.groupMenu {
  display: flex;
}

.factsPrev {
  background-image: url("./files/ic_forward.png");
  width: 50px;
  height: 50px;
  opacity: 0.9px;
}

.factsPause {
  background-image: url("./files/ic_pause.png");
  width: 50px;
  height: 50px;
  opacity: 0.9px;
  display: none;
}
.factsPlay {
  background-image: url("./files/play.png");
  width: 50px;
  height: 50px;
  opacity: 0.9;
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: center;
}
.factsNext {
  background-image: url("./files/ic_forward.png");
  rotate: 180deg;
  width: 50px;
  height: 50px;
  opacity: 0.9px;
}

.factsDuration {
  width: 46px;
  height: 21px;
  gap: 0px;
  border-radius: 3px 0;
  opacity: 0px;
}

.factsFull {
  width: 46px;
  height: 6px;
  border-radius: 3px;
  background-color: #dfd8e8;
}

.factsDone {
  height: 6px;
  border-radius: 3px;
  background-color: #686f7a;
}

.timer {
  margin-top: 5px;
  font-family: Inter;
  font-size: 10px;
  font-weight: 600;
  line-height: 10px;
  text-align: center;
  color: #b0aab8;
}

.smallPlayer {
  width: max-content;
  height: max-content;
  border: 5px solid #ebedf0;
  border-radius: 17px;
  display: flex;
  background-color: white;
}

.rightSidebar {
  display: flex;
  margin: 15px 0;
  width: 15vw;
  float: right;
  justify-content: center;
  min-height: 200px;
}

.progressFull {
  position: relative;
  width: 30px;
  height: 95%;
  border-radius: 15px;
  border: 5px solid #ebedf0;
  background-color: white;
}

.progressDone {
  position: absolute;
  bottom: 0;
  width: 20px;
  border-radius: 10px;
  opacity: 1;
  background: #ffb800;
}

.firstStar {
  position: absolute;
  z-index: 2;
  bottom: 50%;
  width: 20px;
  height: 20px;
  background-image: url("./files/star.svg");
  background-size: cover;
  background-position: center;
}
.firstStarWhite {
  position: absolute;
  z-index: 2;
  bottom: 50%;
  width: 20px;
  height: 20px;
  background-image: url("./files/whiteStar.svg");
  background-size: cover;
  background-position: center;
}

.secondStar {
  position: absolute;
  z-index: 2;
  bottom: 75%;
  width: 20px;
  height: 20px;
  background-image: url("./files/star.svg");
  background-size: cover;
  background-position: center;
}

.secondStarWhite {
  position: absolute;
  z-index: 2;
  bottom: 75%;
  width: 20px;
  height: 20px;
  background-image: url("./files/whiteStar.svg");
  background-size: cover;
  background-position: center;
}

.thirdStar {
  position: absolute;
  z-index: 2;
  bottom: 90%;
  width: 20px;
  height: 20px;
  background-image: url("./files/star.svg");
  background-size: cover;
  background-position: center;
}

.thirdStarWhite {
  position: absolute;
  z-index: 2;
  bottom: 90%;
  width: 20px;
  height: 20px;
  background-image: url("./files/whiteStar.svg");
  background-size: cover;
  background-position: center;
}

.DDmadal {
  position: absolute;
  z-index: 9999999999999999999999999999;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #000000;
}

.modalWrapper {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 15px;
}

.title {
  font-family: CraftworkGrotesk;
  font-size: 32px;
  font-weight: 900;
  line-height: 33.79px;
  text-align: left;
  color: #958807;
}

.modalTitle {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  font-family: CraftworkGrotesk;
  font-size: 18px;
  font-weight: 600;
  line-height: 19.01px;
  text-align: center;
  color: #958807;
}

.miniPlay {
  margin-right: 10px;
  width: 12px;
  height: 14px;
  background-image: url("./files/Vector.svg");
}

.fact {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.factPointer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54.18px;
  height: 54.18px;
  border: 4px solid #958807;
  border-radius: 50%;
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 24.2px;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 24.2px;
  text-align: center;
  color: #958807;
  flex: 0 0 auto;
  opacity: 1;
}

.factText {
  height: auto;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: CraftworkGrotesk;
  font-size: 20px;
  font-weight: 700;
  line-height: 21.12px;
  text-align: left;
  color: #958807;
  opacity: 1;
}
.checked {
  opacity: 0.4;
}

.mainPreview {
  position: absolute;
  z-index: 999999;
  height: 100vh;
  width: 100vw;
  /* background-image:url("https://game.chevostik.ru/uploads/lesson/image/23/large_chaikovsky_preview.jpg"); */
  background-size: cover;
  background-position: center;
}

.mainPreview .rightSidebar {
  align-items: center;
}

.logo {
  top: 50vh;
  left: 50vw;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  background-color: #fff7ce;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.logoTitle {
  font-family: CraftworkGrotesk;
  font-size: 22px;
  font-weight: 700;
  line-height: 26.4px;
  text-align: center;
  color: #b19d22;
}

.logoCounter {
  font-family: CraftworkGrotesk;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: center;
  color: #b19d22;
}

.btnCircle {
  position: absolute;
  top: 150px;
  width: 130px;
  height: 50px;
  background-image: url("./files/btn_circle.png");
  cursor: pointer;
}

video {
  border-radius: 16px;
}

.audioWallpapper {
  width: 100%;
  max-width: 60vh;
  max-height: 40vh;
  /* opacity: 1; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.audioImage {
  width: 90%;
  max-width: 60vh;
  max-height: 40vh;
}
