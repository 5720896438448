.ny
  position: relative
  display: grid
  box-shadow: 0 6px 40px rgba(23, 27, 36, 0.25)
  border-radius: 40px
  color: #FFFFFF
  background-color: #00476F
  background-position: center
  background-repeat: no-repeat
  background-size: contain
  box-sizing: border-box

  @media screen and (max-width: 849px)
    border-radius: 24px

.title
  grid-area: title
  font-weight: 700
  text-transform: uppercase

.sale
  grid-area: sale
  font-weight: 700
  color: #95FC28

.descr
  grid-area: descr

.image
  grid-area: image

.button
  grid-area: button
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  background-color: #95FC28
  border: 8px solid #FFFFFF
  border-radius: 24px
  font-weight: 700
  font-size: 24px
  line-height: 1
  color: #170101
  text-decoration: none
  padding: 16px 8px

.big
  grid-template: repeat(3, auto) 1fr auto / 1fr auto
  grid-template-areas: 'title title' 'sale image' 'descr image' '. image' 'button button'
  gap: 15px 0
  background-image: url('images/bg-big.png')
  max-width: 296px
  width: calc(100vw - 40px)
  font-size: 15px
  line-height: 1.2
  padding: 20px

  .title
    font-size: 40px

  .sale
    font-size: 22px
    line-height: 1
    align-self: center

  @media screen and (max-width: 849px)
    .image
      margin-left: -20px
      margin-top: -20px

  @media screen and (min-width: 850px)
    grid-template-areas: 'title image' 'sale image' 'descr image' '. image' 'button image'
    max-width: 920px
    font-size: 40px
    padding: 48px 16px 48px 48px

    .title
      font-size: 55px

    .sale
      font-size: 56px
      line-height: 1

    .image
      margin-top: -150px
      margin-bottom: -50px

.small
  grid-template: repeat(4, auto) / 1fr auto
  grid-template-areas: 'title image' 'sale image' 'descr image' 'button image'
  gap: 9px 20px
  background-image: url('images/bg-small.png')
  border-radius: 24px
  max-width: 340px
  width: calc(100vw - 40px)
  font-size: 14px
  line-height: 1
  padding: 12px

  .title
    font-size: 20px

    br
      display: none

  .sale
    font-size: 17px

  .image
    margin-bottom: -20px

  @media screen and (max-width: 849px)
    .button
      border-radius: 12px
      border-width: 4px
      font-size: 18px
      padding: 6px

  @media screen and (min-width: 850px)
    grid-template: repeat(4, auto) / auto 1fr
    grid-template-areas: 'image title' 'image sale' 'image descr' 'image button'
    max-width: 712px
    font-size: 27px
    padding: 24px

    .title
      font-size: 36px

    .sale
      font-size: 36px

    .image
      margin-top: -51px
      margin-bottom: -30px

.routes
  grid-template: auto 1fr repeat(3, auto) / 1fr auto
  grid-template-areas: 'title title' '. .' 'sale image' 'descr image' 'button button'
  gap: 10px 0
  border-radius: 24px
  height: 100%
  font-size: 17px
  line-height: 1
  background-image: url('images/bg-routes.png')
  padding: 20px

  .title
    font-size: 32px

  .sale
    font-size: 24px

  @media screen and (min-width: 850px)
    grid-template: auto 1fr repeat(2, auto) 1fr auto / auto 1fr
    grid-template-areas: 'title title' 'image .' 'image sale' 'image descr' 'image .' 'button button'
    gap: 20px 0
    font-size: 18px

    .title
      font-size: 43px

    .sale
      font-size: 30px
