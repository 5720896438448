.container
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  box-sizing: border-box
  padding: 20px
  z-index: 9999

.alert
  position: relative
  z-index: 20

.background
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 10

.closeBtn
  position: absolute
  border: 0
  outline: none
  background: none
  padding: 0

.center
  align-items: center

  .closeBtn
    top: 25px
    right: 25px

  @media screen and (min-width: 850px)
    .closeBtn
      top: 56px
      right: 56px

.bottom
  align-items: flex-end

  .closeBtn
    top: 16px
    right: 16px

  @media screen and (min-width: 850px)
    .closeBtn
      top: 29px
      right: 29px
